.hidden {
  display: none;
}

.authForm {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0;
  padding: 0;
}

.centered-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 100vh; /* This will center vertically within the viewport */
}

.package-table {
  text-decoration: none;
  list-style: none;
  font-weight: lighter;
}

.package-name {
  font-weight: bold;
}

header {
  background-color: #0f3d62;
  color: white;
  padding: 1vw;
}

header span {
  margin: 5px;
}

.navbar {
  margin: 0 2vw;
}

.navbar img {
  width: 15vw;
  margin-left: 3vw;
}

.navList {
  list-style-type: none;
  font-size: large;
  color: #0983bb;
}

.navList li a:hover {
  color: #0f3d62;
  text-decoration: underline;
}

.navDirect a {
  text-decoration: none;
  color: inherit;
}

.navDirect ul {
  display: flex;
}

.navDirect li {
  margin: 0 1.2vw;
}

.buttonContainer {
  display: flex;
}

.buttonContainer .btn {
  width: 9vw;
  background-color: #39c0ee;
  border: none;
}

.login-button {
  margin-right: 2vw;
}

.hero-background {
  background-image: url("./img/hero-background.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: rgb(255, 255, 255);
  padding: 0 20vw;
}

.modal ul {
  list-style-type: none;
  padding: 0;
}

.modal a {
  color: blue;
  text-decoration: none;
}

.modal a:hover {
  color: red;
}

.hero-background h1 {
  margin-bottom: 3vw;
  text-shadow: 2px 2px 3px #000000;
}

.hero-background p {
  font-size: large;
  margin-bottom: 3vw;
  text-shadow: 2px 2px 3px #000000;
}

.hero-background .btn {
  margin: 2vh 3vw;
  padding: 0.7vw;
  font-weight: 400;
  width: 25vw;
  border: none;
  text-decoration: none;
}

.creditworthy {
  margin: 5vh 0;
  text-align: center;
}

.hero-cardgroup {
  margin: 0 15vw;
  text-align: center;
}

.hero-card {
  height: 30vh !important;
  margin: 0 1vw;
  border-color: black !important;
  border-left: black 1px solid !important;
  transition: transform 0.3s ease;
}

.hero-card:hover {
  transform: scale(1.1);
}

.hero-card img {
  width: 3vw;
  margin: 1vw 0;
}

.hero-container {
  flex-direction: column;
  text-align: center;
  margin-top: 5vh;
  align-items: center;
  margin-left: 10vw;
  margin-right: 10vw;
  background-color: #f8f8f8;
  padding-top: 10vh;
  padding-bottom: 10vh;
}

/* .hero-container {
  display: flex;
  justify-content: space-between;
  text-align: center;
  margin-top: 5vh;
  align-items: center;
  margin-left: 10vw;
  margin-right: 10vw;
  background-color: #f8f8f8;
  padding-top: 20vh;
  padding-bottom: 20vh;
}
 */
.hero-text {
  flex: 1;
  padding-right: 20px;
}

.hero-logo {
  align-items: center;
}

.hero-logo img {
  max-height: 205px;
  max-width: 420px;
}

#services span {
  font-weight: bold;
}

.service-title {
  text-align: center;
  margin: 5vh 0;
}

.service-text {
  text-align: center;
  margin: 0 10vw;
  font-weight: 350;
  font-size: large;
}

.service-cardgroup1,
.service-cardgroup2,
.service-cardgroup3 {
  margin: 5vh 15vw;
  text-align: center;
}

.service-card {
  transition: transform 0.3s ease;
}

.service-card:hover {
  transform: scale(1.1);
}

#pricing {
  padding-top: 10vh;
  margin: 10vh 10vw;
  background-color: #f8f8f8;
}

.pricing-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
}

.text-container {
  width: 50%;

  padding: 0 5vw;
}

.text-container h2 {
  font-weight: lighter;
}

.circle-stack {
  display: flex;
  flex-direction: column;
  width: 50%;
  padding: 0 5vw;
}

.circle {
  width: 150px;
  height: 150px;
  background-color: #eaeaea;
  border: 5px solid #0f3d62;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2vh;
}

.circle1 {
  margin-bottom: 20px;
}

.circle2 {
  margin-top: 20px;
  margin-bottom: 20px;
}

.circle h3 {
  font-size: medium;
  line-height: 1.2;
  color: #0f3d62;
  text-align: center;
  margin: 0;
  padding: 0;
}

.circle-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
  border: 1px solid rgb(105, 105, 105);
  background-color: #f8f8f8;
  box-shadow: 10px 10px 100px rgba(0, 0, 0, 0.3);
}

.circle-container h1,
.circle-container h2 {
  margin-top: 1vh;
  text-align: center;
  margin-bottom: 2vh;
}

.price-background {
  background-color: #0f3d62;
  color: white;
  width: 100%;
}

.price-background h1 {
  font-size: x-large;
}

.price-background h2 {
  font-size: large;
  font-weight: lighter;
}

.price-list {
  list-style-type: none;
  padding: 0;
  line-height: 2.5;
  text-align: left;
}

.price-cost {
  font-size: xx-large;
}

.errors-list {
  list-style-type: none;
  padding-left: 20px;
}

.price-image {
  background-image: url("./img/credit-score.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 50vh;
  display: flex;
}

.pricing-container2 {
  margin-top: 10vh;
  color: white;
  text-shadow: 3px 3px 3px #000000;
  padding: 0 3vw;
}

.text-container2 {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.results-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

Button.container-button {
  margin-bottom: 2vh;
  background-color: #39c0ee;
  border: none;
  text-decoration: underline;
}

.fancy-underline {
  position: relative;
}

.fancy-underline::after {
  content: "";
  position: absolute;
  bottom: -3px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #000;
}

.faq-title {
  text-align: center;
}

.faq-title h1 {
  font-weight: bold;
}

.faq-title h2 {
  font-weight: lighter;
  font-size: x-large;
  margin-bottom: 3vh;
}

.accordion-container-wrapper {
  display: flex;
  margin: 0 10vw;
  margin-bottom: 10vh;
}

.accordion-container {
  flex: 1;
}

.accordion-container .accordion-header {
  padding: 10px 20px;
  cursor: pointer;
}

.accordion-container .accordion-body {
  background-color: #ffffff;
  padding: 20px;
  border: 1px solid #c6c6c6;
}

.carousel-image {
  background-image: url("./img/woman-at-pc.jpg");
  margin: 0 15vw;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 75vh;
  display: flex;
  align-items: center;
  color: white;
  padding: 0 5vw;
  text-align: center;
}

.carousel-inner {
  transition-duration: 1s;
}

.carousel-item {
  min-height: 165px;
}

#contact {
  margin: 10vh 10vw;
  background-color: #f8f8f8;
  padding: 5vh 0;
}

.contact-blurb {
  text-align: center;
  margin-bottom: 5vh;
}

.contact-blurb h1 {
  margin-top: 1vh;
  font-weight: bold;
}

.contact-blurb h2 {
  font-weight: lighter;
  font-size: xx-large;
}

.contact-blurb h3 {
  margin-top: 2vh;
  font-weight: lighter;
  font-size: x-large;
}

Button.form-button {
  margin-top: 2vh;
  background-color: #39c0ee;
  border: none;
}

.transparent-input {
  background: transparent;
  border: none;
  border-bottom: 1px solid black;
  padding: 5px;
  outline: none;
}

.transparent-input:focus {
  border-bottom: 1px solid blue;
  /* Customize the color for the focused state */
}

footer {
  background-color: #f8f8f8;
}

.footer-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 10vw;
}

.logo-container img {
  width: 15vw;
}

.links-container {
  display: flex;
}

.links-container a {
  font-weight: bold;
  text-decoration: none;
  margin: 0 1vw;
  color: #7a7a7a;
}

.copy-container {
  margin: 0 10vw;
  margin-top: 10px;
  padding-top: 5vh;
  text-align: center;
  border-top: 1px dashed black;
}

.header-logo {
  font-size: 3vw;
}

.hero-button {
  align-items: center;
  background-image: linear-gradient(135deg, #40c6f3 40%, #4d56fc);
  box-sizing: border-box;
  flex-direction: column;
  justify-content: center;
  letter-spacing: 0.4px;
  touch-action: manipulation;
  text-transform: uppercase;
}

.hero-button:active {
  outline: 0;
}

.hero-button:hover span {
  transform: scale(0.8);
  opacity: 0.75;
}

.hero-button span {
  transition: all 200ms;
  text-align: center;
  text-shadow: 2px 2px 5px #000000;
}

.buttong {
  background-image: linear-gradient(135deg, #55f340 40%, #3bc454);
}

.buttonr{
  background-image: linear-gradient(135deg, #f34040 40%, #c43b3b);
}

.buttonp{
  background-image: linear-gradient(135deg, #cf40f3 40%, #a63bc4);
}

.buttono{
  background-image: linear-gradient(135deg, #f3a540 40%, #c4893b);
}

/* .affiliate-button {
  background-image: linear-gradient(135deg, #55f340 40%, #3bc454);
} */

.hero-background .affiliate-button:hover {
  background-color: #009e08;
}

iframe {
  padding: 5%;
}

.credit-row {
  display: flex;
  flex-direction: row;
}

.sign-up {
  margin-left: 5vw;
  padding-top: 5vh;
}

.sign-p {
  font-weight: bold;
  padding-top: 3vh;
  padding-right: 5vw;
}

@media screen and (min-width: 280px) and (max-width: 359px) {
  .navbar {
    flex-direction: column;
  }

  .navbar img {
    width: 30vw !important;
  }

  .navList {
    padding: 0 !important;
    padding-top: 1vh !important;
    font-size: medium;
  }

  ul {
    margin-bottom: 0.5vh !important;
  }

  .navLogin {
    margin: 0;
    padding: 0;
  }
}

@media screen and (max-width: 450px) {
  header {
    margin: 0;
    padding: 0;
    font-size: small;
    display: flex;
    align-items: center;
  }

  header span {
    font-size: small;
  }

  header .col {
    flex: none;
  }

  header .header-logo {
    font-size: 5vw;
    padding: 2vw;
  }

  .navbar {
    flex-direction: column;
  }

  .navbar img {
    width: 30vw;
  }

  .navList {
    padding: 0;
    padding-top: 1vh;
  }

  .navLogin {
    margin: 0;
    padding: 0;
  }

  .buttonContainer .btn {
    width: initial;
    font-size: x-small;
  }

  /*  .navbar {
    margin: 0;
    padding: 0;

  }

  .navbar img {
    width: 20vw;
    margin-left: 0;
  }

  .navList {
    font-size: xx-small;
    padding-right: 3vw;
    padding-top: 3vh;
  }

  .navbar .btn {
    font-size: .4rem;
    padding: .5vh;
    margin: 0 .8vw 0 0;
  }
 */
  .hero-background {
    margin: 0;
    padding: 0 7vw;
    height: 70vh;
  }

  .hero-background h1 {
    font-size: large;
  }

  .hero-background p {
    font-size: x-small;
  }

  .hero-background .btn {
    font-size: x-small;
    width: 60vw;
    padding: 2vw;
  }

  .hero-card {
    height: 30vh !important;
  }

  .hero-card img {
    width: 8vw;
    margin: 1vw 0;
  }

  .hero-container {
    flex-direction: column;
    margin: 0;
    padding: 0;
  }

  .hero-text {
    padding: 5vw;
    padding-bottom: 0;
    margin: 0;
  }

  .hero-text h1 {
    font-size: large;
  }

  .hero-text p {
    font-size: x-small;
  }

  .hero-logo {
    margin: 0;
    padding: 0;
  }

  .hero-logo img {
    max-height: 100px;
    max-width: 200px;
  }

  .service-cardgroup1,
  .service-cardgroup2,
  .service-cardgroup3 {
    margin: 5vh 10vw;
    padding: 0;
  }

  .service-card {
    height: 25vh !important;
    width: 80vw;
  }

  .service-title {
    margin: 5vh 0;
    font-size: x-large;
  }

  .service-text {
    margin: 0 10vw;
    font-size: small;
  }

  .pricing-container {
    flex-direction: column;
    margin: 0;
    padding: 0;
  }

  .text-container {
    width: 100%;
    margin-top: 0;
    padding: 0 5vw;
    text-align: center;
  }

  .text-container h2 {
    font-size: medium;
  }

  .circle-stack {
    width: 100%;
    padding: 5vh 5vw;
  }

  .fancy-underline {
    text-decoration: underline;
    text-underline-offset: 5px;
  }

  .price-image {
    height: 60vh;
  }

  .pricing-container2 {
    display: flex;
    flex-direction: column;
    text-align: center;
    margin-top: 0;
  }

  .text-container2 {
    width: 100%;
    margin-top: 1vh;
    padding: 0 5vw;
    text-align: center;
  }

  .results-container {
    width: 100%;
    padding: 0;
    margin-bottom: 7vh;
    text-align: center;
  }

  .results-container h2 {
    font-size: large;
  }

  .errors-list {
    padding: 0;
    font-size: small;
  }

  .mobile-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0;
    padding: 0;
  }

  #faq {
    margin: 5vh 0;
  }

  .faq-title h2 {
    margin: 0 10vw;
  }

  .accordion-container-wrapper {
    display: flex;
    flex-direction: column;
    margin: 0;
    margin-top: 5vh;
    padding: 0;
  }

  .carousel-image {
    margin: 5vw;
  }

  .carousel-item {
    min-height: 315px;
  }

  .contact-blurb h2 {
    font-size: x-large;
    margin: 2vh 0;
  }

  .contact-blurb h1 {
    font-size: xx-large;
    margin: 2vh 0;
  }

  .contact-blurb h3 {
    font-size: large;
    margin: 2vh 0;
  }

  .contact-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0;
    padding: 0;
  }

  .contact-container .btn {
    width: 100%;
    margin: 2vh 0;
  }

  .contact-info {
    padding: 1vh 0 !important;
  }

  .footer-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0;
    padding: 0;
  }

  .logo-container img {
    width: 50vw;
  }

  .links-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0;
    padding: 3vh 0;
  }

  .links-container a {
    margin: 1vh 0;
  }

  .credit-row {
    display: flex;
    flex-direction: column;
    width: 80%;
  }

  iframe {
    padding: 0;
    padding-bottom: 10vh;
    width: 100%;
    margin-left: 5vh;
  }

  .centered-container {
    height: initial;
    font-size: smaller;
  }

  .sign-up {
    text-align: center;
    width: 100%;
    margin-left: 5vh;
  }

  .package-table {
    font-weight: 500;
  }
}
